import imageTwo from '../../assets/images/1.png'
// import img from '../../assets/images/'

export const slides = [
    {
        name: '-James Watt',
        testimonial: `I've been in the
        basmati business for
        years, but this book
        opened my eyes to
        new possibilities. The
        7 Strategies are a
        game-changer for
        any basmati rice
        importer!
        `,
        city: 'Basmati Rice Importer',
        image: imageTwo,
        alt: 'Imageone',
    },
    {
        name: '- Mr. Azah Fareed',
        testimonial: `This book is a gamechanger! It's packed
        with practical
        strategies that
        helped us boost
        profits and gain
        customer loyalty. A
        must-read for
        anyone in the
        basmati rice industry!`,
        city: 'Basmati Rice Importer',
        image: imageTwo,
        alt: 'ImageTwo',
    },
    {
        name: '- Ganga Kishor',
        testimonial: `Mr.Mohit’s book is a
        gold mine of insights.
        Their marketing
        strategies are a gamechanger for basmati
        rice importers.
        Highly recommended!`,
        city: 'Basmati Rice Importer',
        image: imageTwo,
        alt: 'Imagethree',
    },
]