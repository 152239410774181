export const faqs = [
    {
        id: 1,
        ques: '1. Tailored to Basmati Rice Industry:',
        ans: `This book focuses specifically on the basmati rice industry, providing
        insights and strategies directly applicable to your unique circumstances.`
    },
    {
        id: 2,
        ques: `2. Author's Expertise and Experience`,
        ans: ` Mr.Mohit Gupta, the authors, is a seasoned basmati rice expert and
        manufacturer with a wealth of experience in the field.`
    },
    {
        id: 3,
        ques: '3. Actionable Strategies',
        ans: `Each chapter offers practical, proven techniques and real-life examples that you
        can implement immediately.`
    },
    {
        id: 4,
        ques: `4. Engaging and Inspiring`,
        ans: `The book is written in an engaging and friendly tone, with captivating stories and
        motivational quotes to inspire and motivate you.`
    },
    {
        id: 5,
        ques: '5. Personalised Solutions',
        ans: `You have the exclusive opportunity to book a 121 session with Mr.Mohit Gupta,
        India’s leading basmati rice expert to discuss your individual challenges and receive personalized guidance.`
    },
 
]

